import styled from 'styled-components';
import { colors } from '../utils/theme';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import media from 'styled-media-query';
import Page from '../components/page';
import Image from 'next/image';
import NextImage from 'next/image';
import Carousel from '../utils/carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Head from 'next/head';
import { gql } from '@apollo/client';
import client from '../utils/apolloClient';
import getAmplitude from '../utils/amplitude';
import dynamic from 'next/dynamic';

const ContactForm = dynamic(() => import('../components/contactForm'), { ssr: false });
const SearchBar = dynamic(() => import('../components/searchBar'), { ssr: false });

const GET_APPS = gql`
  query getData {
    apps: getAvailableApps {
      id
      name
      icon
      squareLogo
      appDetail
      colour
      sponsoredLogo
      sponsoredLogoLink
      vouchersEnabled
      isActive
      heroImage
      firstSponsor
      secondSponsor
      thirdSponsor
      fourthSponsor
      fifthSponsor
      sixthSponsor
      firstSponsorLink
      secondSponsorLink
      thirdSponsorLink
      fourthSponsorLink
      fifthSponsorLink
      sixthSponsorLink
      slug
      blurhash
      location {
        coordinates
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 680px;

  & div img {
    object-fit: cover;
  }

  ${media.lessThan('large')`
        height: 476px;
  `}

  ${media.lessThan('small')`
        height: 275px;
  `}
`;

const Header = styled.div`
  background-image: url('/bigBlob.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-position-y: -100px;
  background-position-x: 50px;

  ${media.greaterThan('medium')`
    background-size: cover;
    background-position: left top;
    background-position-y: -200px;
    background-position-x: 300px;
  `};

  ${media.greaterThan('1920px' as any)`
    background-position: left top;
    background-position-y: -500px;
    background-position-x: 1100px;
    background-size: 70%;
  `};
`;

const FloatingImage = styled.img`
  @media (max-width: 350px) {
    top: -10.5rem;
  }

  @media (max-width: 340px) {
    top: -7rem;
  }
`;

const HeaderPhone = styled.img`
  ${media.between('1280px' as any, '1439px' as any)`
    margin-left: 15rem;
  `}
`;

const ImageText: React.FC<{ text: string }> = ({ text }) => (
  <h3 className="p-2 md:p-16 lg:p-0 text-center lg:text-left  text-md md:text-xl lg:text-2xl xl:text-3xl text-white absolute left-0 lg:left-24 xl:left-44 bottom-2 md:bottom-2 lg:bottom-16 xl:bottom-44 lg:max-w-lg xl:max-w-xl ">
    {text}
  </h3>
);

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
`;

const GALLERY_IMAGES = 5;
function Home({ data }) {
  const apps = data?.apps?.filter(a => a.isActive) ?? [];
  const iosURL = 'https://apps.apple.com/us/app/xplore-local/id1541114396';
  const androidURL = 'https://play.google.com/store/apps/details?id=com.booniverse.xplore';
  const [galleryIndex, setGalleryIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (galleryIndex >= GALLERY_IMAGES - 1) {
        setGalleryIndex(0);
      } else {
        setGalleryIndex(galleryIndex + 1);
      }
    }, 7000);

    return () => clearInterval(id);
  });
  return (
    <Page noMarginTop>
      <Head>
        <link rel="preload" as="image" href={'/phone.png'} />
      </Head>
      <Header style={{ backgroundColor: '#57B894' }} className="flex justify-center">
        <div className="p-4 md:p-24 pb-32 mt-24 md:mt-24 flex xl:row lg:col max-w-screen-xl w-full relative overflow-hidden lg:overflow-visible">
          <div className="w-full lg:w-4/6 mb-80 lg:mb-0">
            <h2 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl m-0">The Xplore App</h2>
            <h2 className="text-3xl  md:text-4xl xl:text-5xl 2xl:text-6xl m-0">is all about Connection</h2>
            <div className="text-lg lg:text-xl lg:w-5/6 mt-8 md:mt-12 mb-24 font-semibold leading-tight">
              We believe that our towns have so much to offer, from their rich, buried histories to the shops that line their streets. We are committed
              to shining a light on the things that make these places so unique, and devoted to making the path to their discovery that bit shorter.
            </div>
            <div className="w-full mb-12 lg:mb-0 lg:max-w-md">{<SearchBar apps={apps} noTitle />}</div>
          </div>
          <HeaderPhone
            alt={''}
            src={'/phone.png'}
            className="pointer-events-none absolute transform -translate-x-1/2 -bottom-52 left-1/2 md:right-0 md:-bottom-52 lg:transform-none lg:bottom-0 lg:ml-20 xl:ml-72 2xl:ml-80"
          />
        </div>
      </Header>

      <div className="w-full flex justify-center" style={{ backgroundColor: '#0f1f2e' }}>
        <div
          className="flex justify-between items-center  w-full md:w-10/12 max-w-screen-xl pl-4 md:pl-24 md:pr-24"
          style={{ backgroundColor: '#0f1f2e' }}>
          <div className="flex flex-row items-center lg:justify-start justify-center w-full pt-2 pb-2 md:pt-0 md:pb-0">
            <div className="mr-4 flex items-center pt-2 pb-2">
              <Image
                alt={'Download iOS app'}
                className="cursor-pointer"
                onClick={async () => {
                  window.open(iosURL, '_blank');
                  const amplitude = await getAmplitude();
                  amplitude.logEvent('Clicked on download iOS app');
                }}
                src={'/appStore.svg'}
                width={150}
                height={42}
              />
            </div>
            <div className="mr-4 flex items-center  pt-2 pb-2">
              <Image
                className="cursor-pointer"
                onClick={async () => {
                  window.open(androidURL, '_blank');
                  const amplitude = await getAmplitude();
                  amplitude.logEvent('Clicked on download android app');
                }}
                alt={'Download android app'}
                src={'/googlePlay.png'}
                width={150}
                height={45}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mb-32 md:mb-12 lg:mb-0">
        <div className="max-w-screen-xl w-full p-8 pt-24 pb-12 flex flex-col lg:flex-row justify-between">
          <img alt={''} src={'/illustration1.svg'} className="w-full lg:ml-16 2xl:ml-0 sm:w-5/6 md:w-2/5 mx-auto mb-16 lg:mr-16" />
          <div className="w-full">
            <h2 className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-0">Why</h2>
            <div className="text-lg md:text-xl leading-tight">
              We humans are always seeking out new ways to connect with other people and our surroundings; for years, technology has been helping us
              achieve this. It’s becoming more and more apparent, however, that tools like social media are doing more harm than good. We’re struggling
              to connect with the people inside of our homes. We hardly know, let alone speak to our neighbours anymore. Online communities, as we’re
              discovering, just aren’t a good enough replacement for the real thing.
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center relative p-8 md:p-24 lg:pt-0">
        <div className="max-w-screen-xl w-full flex justify-between z-10 relative">
          <div className="xl:ml-16  2xl:ml-8 3xl:ml-0 mt-80 md:mt-70 lg:mt-12 md:w-full lg:w-4/6 lg:self-start items-center">
            <h2 className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mb-0">Xplore is for the adventurer </h2>
            <p className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-0 font-bold">(and the adventurous local)</p>
            <div className="text-lg md:text-xl lg:w-10/12 leading-tight">
              Whether you are lucky enough to call one of our listed towns home, or you’re just visiting, we hope you’ll find everything you need here
              to make the most of it.
            </div>
            <br />
            <div className="text-lg md:text-xl lg:w-10/12 leading-tight">
              We believe that our towns have so much to offer, from their rich, buried histories to the shops that line their streets. We are committed
              to shining a light on the things that make these places so unique, and devoted to making the path to their discovery that bit shorter.
            </div>
          </div>
          <FloatingImage
            alt={''}
            src={'/indexphone2.png'}
            className="absolute z-10 left-1/2 lg:ml-32 transform -translate-x-1/2 w-full md:w-3/5 lg:w-1/2 lg:transform-none -top-64 lg:-top-16 xl:-top-28 md:-right-24 lg:-right-8"
          />
        </div>
        <div
          style={{ backgroundColor: 'rgba(87, 184, 148, .5)' }}
          className="bg-right w-full xl:w-11/12 absolute h-full md:h-5/6 lg:h-full z-0 xl:rounded-l-3xl right-0"
        />
      </div>

      <div className="flex justify-center relative mt-80 md:mt-48 xl:mt-96 p-8 md:p-24">
        <div className="max-w-screen-xl w-full flex justify-between z-10 relative">
          <div className="xl:ml-16 2xl:ml-0 mt-44 md:mt-70 lg:mt-12 md:w-full lg:w-4/6 lg:self-start items-center">
            <h2 className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-0">Xplore believes in supporting local communities</h2>
            <div className="self-start w-full  md:w-4/5 xl:w-4/6">
              <div className="text-lg md:text-xl leading-tight">
                We believe that a thriving community is a well-informed, inclusive and united one. It’s no secret that investment in the local economy
                is crucial to the prosperity and development of our communities.
              </div>
              <br />
              <div className="text-lg md:text-xl leading-tight">
                Our platform provides a framework for initiatives that support this mission. We want to help communities help themselves through web
                and app features that promote interaction, participation, and insight. This includes the showcasing of local businesses and systems
                like Xplore Local Vouchers: virtual tokens which can only be spent on goods and services within your community.
              </div>
            </div>
          </div>

          <FloatingImage
            alt={''}
            src={'/business.svg'}
            className="absolute z-10 left-1/2 lg:ml-20 transform -translate-x-1/2 w-full md:w-3/5 lg:w-2/5 lg:transform-none -top-52 md:-top-32 lg:top-32"
          />
        </div>
        <div
          style={{ backgroundColor: 'rgba(87, 184, 148, .5)' }}
          className="bg-right w-full xl:w-11/12 absolute h-full md:h-5/6 z-0 xl:rounded-r-3xl left-0"
        />
      </div>

      <h2 className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-32 md:mt-8 self-center">App features</h2>
      <div className="relative w-full">
        {galleryIndex < GALLERY_IMAGES - 1 && (
          <FaChevronRight
            onClick={() => setGalleryIndex(galleryIndex + 1)}
            className="text-white absolute right-2 md:right-16 cursor-pointer z-50 top-1/2 transform -translate-y-1/2 opacity-80"
            style={{ fontSize: '3rem' }}
          />
        )}

        {galleryIndex > 0 && (
          <FaChevronLeft
            onClick={() => setGalleryIndex(galleryIndex - 1)}
            className="text-white absolute left-2 md:left-16 cursor-pointer z-50 top-1/2 transform -translate-y-1/2 opacity-80"
            style={{ fontSize: '3rem' }}
          />
        )}
        <Carousel value={galleryIndex} draggable onChange={setGalleryIndex} plugins={['arrows']}>
          <ImageWrapper>
            <NextImage src={'/XploreHomeSlides_1.jpeg'} layout={'fill'} alt={'gallery image'} />
            <Overlay />
            <ImageText text="Discover and Promote the Natural Beauty of your Surroundings with Hikes and Trails." />
          </ImageWrapper>
          <ImageWrapper>
            <NextImage src={'/XploreHomeSlides_2.jpeg'} layout={'fill'} alt={'gallery image'} />
            <Overlay />
            <ImageText text="Highlight and Share the History and Culture of your town with Self-Guided Tours." />
          </ImageWrapper>
          <ImageWrapper>
            <NextImage src={'/XploreHomeSlides_3.jpeg'} layout={'fill'} alt={'gallery image'} />
            <Overlay />
            <ImageText text="Support Local Business from anywhere in the World with Local Vouchers." />
          </ImageWrapper>
          <ImageWrapper>
            <NextImage src={'/XploreHomeSlides_4.jpeg'} layout={'fill'} alt={'gallery image'} />
            <Overlay />
            <ImageText text="Shine a light on the people and organisations that define your community." />
          </ImageWrapper>
          <ImageWrapper>
            <NextImage src={'/XploreHomeSlides_5.jpeg'} layout={'fill'} alt={'gallery image'} />
            <Overlay />
            <ImageText text="Inform your community and encourage participation with news and alerts." />
          </ImageWrapper>
        </Carousel>
      </div>

      <div className="flex justify-center relative  p-8 md:p-24" style={{ backgroundColor: '#F3F6F9' }}>
        <div className="max-w-screen-xl w-full flex flex-col lg:flex-row justify-between z-10 relative">
          <div className="w-full lg:w-8/12 self-start flex flex-col ">
            <h2 className="text-2xl md:text-4xl xl:text-5xl 2xl:text-6xl mt-0">Create a digital infrastructure for your town</h2>
            <div className="self-start w-10/12">
              <div className="text-lg md:text-xl leading-tight">Interact with your community and visitors like never before.</div>
              <br />
              <div className="text-lg md:text-xl leading-tight">
                Showcase the History, Culture and Heritage of your town in an interactive and exciting way. Highlight local businesses, causes and
                transact!
              </div>
            </div>
          </div>

          <img alt={''} src={'/city.svg'} className="mt-8 md:mt-0" />
        </div>
      </div>

      <div className="flex justify-center p-4 md:p-24 pb-16 md:pb-32 lg:pb-24 overflow-hidden" style={{ backgroundColor: colors.black }}>
        <div className="max-w-screen-xl w-full  flex justify-between">
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <p className="text-2xl md:text-4xl font-bold mb-0 text-white">Interested in our app?</p>
              <p className="text-2xl md:text-4xl  font-bold mt-0 text-white">Contact us today, we're ready</p>
              <p className={'text-md md:text-lg text-white mb-8'}>Fill out our form and we will contact you again to present our application.</p>
            </Grid>

            <Grid item xs={12} md={7}>
              <ContactForm />
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
}

export async function getStaticProps() {
  const apolloClient = client;
  const { data } = await apolloClient.query({ query: GET_APPS });
  return { props: { data }, revalidate: 1800 };
}

export default Home;
