import React from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import classNames from 'classnames';

const Page: React.FC<{ noMarginTop?: boolean }> = ({ children, noMarginTop }) => {
  const router = useRouter();
  return (
    <div className={classNames('flex flex-col mt-[110px] md:mt-[110px]', { '!mt-0': noMarginTop })}>
      <NextSeo
        canonical={`https://xploreapp.io${router.asPath}`}
        facebook={{
          appId: '507054450225530',
        }}
      />
      {children}
    </div>
  );
};

export default Page;
